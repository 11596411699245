import React, { useContext } from "react";

import Col from "react-bootstrap/Col";

import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { BsInstagram } from "react-icons/bs";

import { ThemeContext } from "../../Theme";
import HotIceWhite from "../../assets/logos/hotice_text_white.svg";
import HotIceBlack from "../../assets/logos/hotice_text_black.svg";
import HotIce3DLogo from "../../assets/logos/hotice_logo_ani.gif";

function HotIce() {
  const { theme } = useContext(ThemeContext);

  return (
    <>
      <Col xl={4} className="hoticeCol">
        <img
          src={HotIce3DLogo}
          alt="HotIce 3D logo"
          title="HotIce 3D logo"
          className="hotice3D"
        />
      </Col>
      <Col xl={8} id="hoticeTextCol" className="hoticeCol">
        <img
          src={theme === "dark-theme" ? HotIceWhite : HotIceBlack}
          className="hoticeTextLog"
          alt="HotIce"
          title="HotIce"
        />

        <div className="hoticeTextdiv">
          <p className="hoticeText">
            HOTICE is a multi-creative studio based in Stockholm. A space where
            many ideas and vision comes to life without any limits and rules on
            how art should be created. HOTICE also offers a variety of
            assistance with your project needs, ranging from copywrite,
            photography to 3D and more. Visit HOTICE to learn more and let's
            stay in touch.
          </p>
        </div>

        <h6>Website still under construction</h6>

        {/* Button to visit the HOTICE website */}
        {/* <button className="visitHoticeBtn">
          <a
            href="https://hotice.se"
            target="_blank"
            rel="noreferrer"
            className="visitHoticeLink"
          >
            Visit HOTICE <MdKeyboardDoubleArrowRight size={30} />
          </a>
        </button> */}

        <div className="HoticeSocial">
          <a
            href="https://www.instagram.com/hotice.se/"
            target="_blank"
            rel="noreferrer"
            className="hoticeInstaLink"
          >
            <BsInstagram id="hoticeInstagram" alt="instagram" />
          </a>
        </div>
      </Col>
    </>
  );
}

export default HotIce;
