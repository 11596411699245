import React, { useContext } from "react";
import { TbSunMoon } from "react-icons/tb";
import { ThemeContext } from "../../Theme";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

function FactsColumn() {
  const { toggleTheme } = useContext(ThemeContext);
  return (
    <Col xs={{ order: 2 }} xl={{ order: 3 }} id="factsCol">
      <Container className="factsContainer">
        <Row className="factsRow">
          <Col id="factsTitle">
            <ul className="ulFacts">
              <li>name</li>
              <li>zodiac</li>
              <li>Chinese zodiac</li>
              <li>nationality</li>
              <li>occupation</li>
            </ul>
          </Col>
          <Col id="factsAnswer">
            <ul>
              <li>Chattria T. Palm</li>
              <li>libra</li>
              <li>monkey</li>
              <li>swe / th</li>
              <li>Artist and co-founder of HOTICE</li>
            </ul>
          </Col>
        </Row>

        <Row className="themeBtnRow">
          <Col className="ThemeModeCol">
            <button
              type="button"
              title="Switch between dark and light mode"
              aria-label="Switch between dark and light mode"
              id="themeBtnAbout"
              className="clickMode"
              onClick={() => toggleTheme()}
            >
              <TbSunMoon id="ThemeBtnIcon" />
            </button>
          </Col>
        </Row>

        <Col id="factsSkills">
          <h2 className="columnRubrik skillRubrik">Skills</h2>
          <Row>
            <Col className="skillColOne">
              <ul id="skillLineOne">
                <li>3D Art</li>
                <li>Digital Fashion</li>
                <li>Graphic Design</li>
                <li>HTML & CSS</li>
                <li>JavaScript</li>
              </ul>
            </Col>
            <Col className="skillColTwo">
              <ul id="skillLineTwo">
                <li>Painter</li>
                <li>Project managment</li>
                <li>Photography</li>
                <li>React</li>
                <li>Retouching</li>
              </ul>
            </Col>
          </Row>
        </Col>
      </Container>
    </Col>
  );
}

export default FactsColumn;
