import { BiLogoLinkedin, BiLogoCodepen } from "react-icons/bi";
import { BsInstagram, BsGithub } from "react-icons/bs";
import { PiCodesandboxLogo } from "react-icons/pi";

export default function Social() {
  return (
    <>
      <div className="col-lg-9 col-xl-6  footer-content__social">
        <div className="row g-0 social-box">
          <div className="col-2 soc">
            <a
              href="https://codesandbox.io/u/chattria"
              target="_blank"
              rel="noreferrer"
            >
              <PiCodesandboxLogo id="codesandbox" alt="codesandbox" />
            </a>
          </div>

          <div className="col-2 soc">
            <a
              href="https://codepen.io/chattria"
              target="_blank"
              rel="noreferrer"
            >
              <BiLogoCodepen id="codepen" alt="codepen" />
            </a>
          </div>

          <div className="col-2 soc">
            <a
              href="https://github.com/chattria"
              target="_blank"
              rel="noreferrer"
            >
              <BsGithub id="github" alt="github" />
            </a>
          </div>

          <div className="col-2 soc">
            <a
              href="https://www.instagram.com/chattria/"
              target="_blank"
              rel="noreferrer"
            >
              <BsInstagram id="instagram" alt="instagram" />
            </a>
          </div>

          <div className="col-2 soc">
            <a
              href="https://www.linkedin.com/in/chattria-thinroj-palm-0b17a7136/"
              target="_blank"
              rel="noreferrer"
            >
              <BiLogoLinkedin id="linkedin" alt="linkedin" />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
