import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Col from "react-bootstrap/Col";

const ContactColumn = () => {
  const [status, setStatus] = useState("Send");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("Sending...");
    const { fullname, email, subject, message } = e.target.elements;
    let details = {
      fullname: fullname.value,
      email: email.value,
      subject: subject.value,
      message: message.value,
    };
    let response = await fetch("https://chattria.com:3306/send", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(details),
    });
    setStatus("Send");
    let result = await response.json();
    toast.success(result.status, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      progress: undefined,
      theme: "colored",
    });
  };

  return (
    <Col xl={{ order: 4 }} id="contactCol">
      <h2 className="columnRubrik" id="contactRubrik">
        Contact
      </h2>

      <form className="contact-form" onSubmit={handleSubmit}>
        <div className="divInput">
          <label htmlFor="fullname" />
          <input type="text" id="fullname" placeholder="Fullname" required />
        </div>
        <div className="divInput">
          <label html="email" />
          <input type="email" id="email" placeholder="Email" required />
        </div>
        <div className="divInput">
          <label html="subject" />
          <input type="text" id="subject" placeholder="Subject" required />
        </div>
        <div className="divInput">
          <label htmlFor="message" />
          <textarea
            type="text"
            id="message"
            rows={23}
            placeholder="Message"
            required
          />
        </div>
        <div className="sendBtnDiv">
          <button type="submit" className="sendBtn">
            {status}
          </button>
        </div>
      </form>
      <ToastContainer />
    </Col>
  );
};

export default ContactColumn;
