import React, { useState } from "react";
import "./Contact.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Contact = () => {
  const [status, setStatus] = useState("Send");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("Sending...");
    const { fullname, email, subject, message } = e.target.elements;
    let details = {
      fullname: fullname.value,
      email: email.value,
      subject: subject.value,
      message: message.value,
    };
    let response = await fetch("https://chattria.com:3306/send", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(details),
    });
    setStatus("Send");
    let result = await response.json();
    toast.success(result.status, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      progress: undefined,
      theme: "colored",
    });
  };

  return (
    <Container fluid className="contactContainer">
      <Row className="contactRow">
        <Col className="contactCol">
          <form className="contactForm" onSubmit={handleSubmit}>
            <div className="contactDivInput">
              <label htmlFor="fullname" />
              <input
                type="text"
                id="contactFullname"
                placeholder="Fullname"
                required
              />
            </div>
            <div className="contactDivInput">
              <label html="email" />
              <input
                type="email"
                id="contactEmail"
                placeholder="Email"
                required
              />
            </div>
            <div className="contactDivInput">
              <label html="subject" />
              <input
                type="text"
                id="contactSubject"
                placeholder="Subject"
                required
              />
            </div>
            <div className="contactDivInput">
              <label htmlFor="message" />
              <textarea
                type="text"
                id="contactMessage"
                rows={34}
                placeholder="Message"
                required
              />
            </div>
            <div className="contactSendBtnDiv">
              <button type="submit" className="contactSendBtn">
                {status}
              </button>
            </div>
          </form>
          <ToastContainer />
        </Col>
      </Row>
    </Container>
  );
};

export default Contact;
