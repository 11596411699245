import React from "react";
import Soon from "../CommingSoon/Soon";

export default function Portfolio() {
  return (
    <div>
      <Soon />
    </div>
  );
}
