import React, { useContext } from "react";
import "./NavbarWeb.css";
import NavbarPages from "./NavbarPages";
import NavbarTitle from "./NavbarTitle";
import NavMobile from "./NavMobile";
import CTPLogo from "../../assets/logos/ctp-logo.png";

import { TbSunMoon } from "react-icons/tb";
import { ThemeContext } from "../../Theme";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function Navbar() {
  const { toggleTheme } = useContext(ThemeContext);

  return (
    <Container fluid text-center id="navbar">
      <Row lg={1} xl={4} id="navbarRow">
        <Col className="ruta">
          <a href="/Projects">
            <img src={CTPLogo} className="logo" alt="CTP logo"></img>
          </a>
          <div className="mobileNavMenu">
            <NavMobile />
          </div>
          <button
            type="button"
            title="Switch between dark and light mode (currently dark mode)"
            aria-label="Switch between dark and light mode (currently dark mode)"
            id="themeBtnHeader"
            className="clickMode"
            onClick={() => toggleTheme()}
          >
            <TbSunMoon size="35px" />
          </button>
        </Col>
        <Col lg={12} xl={9}>
          <Row>
            <NavbarTitle />
          </Row>
          <Row className="navMenuRuta">
            <nav className="primaryNav" aria-label="primary">
              <NavbarPages />
            </nav>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
