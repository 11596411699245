import React from "react";
import "./Soon.css";

function Soon() {
  return (
    <div class="marquee">
      <div class="marqueeContent">
        <span>coming soon ➺ </span>
        <span>coming soon ➺ </span>
        <span>coming soon ➺ </span>
      </div>
      <div class="marqueeContent" aria-hidden="true">
        <span>coming soon ➺ </span>
        <span>coming soon ➺ </span>
        <span>coming soon ➺ </span>
      </div>
    </div>
  );
}

export default Soon;
