import React, { useState } from "react";

export default function NavMobile() {
  return (
    <Navbar>
      <NavItem text="menu">
        <DropdownMenu />
      </NavItem>
    </Navbar>
  );
}

function DropdownMenu() {
  function CustomLink({ href, children, ...props }) {
    const path = window.location.pathname;
    return (
      <li className={path === href ? "active" : ""} id="menu-item">
        <a href={href} {...props}>
          {children}
        </a>
      </li>
    );
  }

  return (
    <div className="dropdown">
      <div className="menu">
        <CustomLink>
          <a href="/Projects">Projects</a>
        </CustomLink>
        <CustomLink>
          <a href="/Portfolio">Portfolio</a>
        </CustomLink>
        <CustomLink>
          <a href="/About">About</a>
        </CustomLink>
        <CustomLink>
          <a href="/Contact">Contact</a>
        </CustomLink>
      </div>
    </div>
  );
}

function Navbar(props) {
  return (
    <nav className="navbar">
      <ul className="navbar-nav"> {props.children} </ul>
    </nav>
  );
}

function NavItem(props) {
  const [open, setOpen] = useState(false);

  return (
    <li className="nav-item">
      <a href="#" className="menu-btn" onClick={() => setOpen(!open)}>
        {props.text}
      </a>
      <div className={`drop ${open ? "active" : "inactive"}`}>
        {open && props.children}
      </div>
    </li>
  );
}
