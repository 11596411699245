import React, { useContext } from "react";
import "./About.css";

import { ThemeContext } from "../../Theme";
import CTPProfilePicW from "../../assets/profilePicture/profilbild-3.jpg";
import CTPProfilePicB from "../../assets/profilePicture/profilepic-web-1.jpg";
import AboutTextColumn from "./AboutTextColumn";
import FactsColumn from "./FactsColumn";
import ContactColumn from "./ContactColumn";
import HotIce from "./HotIce";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function About() {
  const { theme } = useContext(ThemeContext);

  return (
    <Container fluid text-center id="aboutContainer">
      <Row xs={1} xl={4}>
        <AboutTextColumn />

        <Col xs={{ order: 1 }} lg={{ order: 2 }} id="profilePicsCol">
          <img
            src={theme === "dark-theme" ? CTPProfilePicB : CTPProfilePicW}
            alt="Chattria"
            className="profilCatti"
          />
        </Col>

        <FactsColumn />

        <ContactColumn />
      </Row>
      <Row>
        <HotIce />
      </Row>
    </Container>
  );
}
