import React from "react";
import Col from "react-bootstrap/Col";

export default function NavbarPages() {
  return (
    <>
      <Col md={4} id="projecstCol" className="navCol">
        <a href="/Projects" className="navMenuLink">
          <p className="navMenu">
            projects
            <br />
            <em className="navMenuDiscription">personal work</em>
          </p>
        </a>
      </Col>
      <Col md={4} id="portfolioCol" className="navCol">
        <a href="/Portfolio" className="navMenuLink">
          <p className="navMenu">
            portfolio
            <br />
            <em className="navMenuDiscription">client work</em>
          </p>
        </a>
      </Col>
      <Col md={4} id="aboutCol" className="navCol">
        <a href="/About" className="navMenuLink">
          <p className="navMenu">
            about
            <br />
            <em className="navMenuDiscription">& contact</em>
          </p>
        </a>
      </Col>
    </>
  );
}
