import React from "react";
import Col from "react-bootstrap/Col";

export default function NavbarTitle() {
  let title;
  if (window.location.pathname === "/") {
    title = "Projects";
  } else {
    title = window.location.pathname.split("/").slice(-1);
  }

  return (
    <>
      <Col>
        <p className="showCurrentNavMenu">{title}</p>
      </Col>
    </>
  );
}
