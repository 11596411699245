import React, { useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import NavbarWeb from "./components/Navbar/NavbarWeb";
import Projects from "./components/Projects/Projects";
import Portfolio from "./components/Portfolio/Portfolio";
import About from "./components/About/About";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";

import { Route, Routes } from "react-router";
import { ThemeContext } from "./Theme";

export default function App() {
  const { theme } = useContext(ThemeContext);
  return (
    <div className={`App ${theme}`}>
      <React.Fragment>
        <NavbarWeb />
        <Routes>
          <Route path="/" element={<Projects />} />
          <Route path="/Projects" element={<Projects />} />
          <Route path="/Portfolio" element={<Portfolio />} />
          <Route path="/About" element={<About />} />
          <Route path="/Contact" element={<Contact />} />
        </Routes>
        <Footer />
      </React.Fragment>
    </div>
  );
}
