import React, { useContext } from "react";
import "./Footer.css";
import FooterSocial from "./FooterSocial";
import { TbSunMoon } from "react-icons/tb";
import { ThemeContext } from "../../Theme";

function Footer() {
  const { toggleTheme } = useContext(ThemeContext);
  let dates = new Date();
  let year = dates.getFullYear();

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="row g-0">
          <div className="col-lg-3 footer-content__start">
            <button
              type="button"
              title="Switch between dark and light mode"
              aria-label="Switch between dark and light mode"
              id="themeBtnFooter"
              className="clickMode"
              onClick={() => toggleTheme()}
            >
              <TbSunMoon size="40px" />
            </button>
          </div>
          <FooterSocial />
          <div className="col-lg-3 col-xl-3 footer-content__end">
            <p className="copyright">©{year} CTP, Chattria T. Palm</p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
