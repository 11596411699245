import React from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

import { BiLogoLinkedin } from "react-icons/bi";
import { BsInstagram, BsGithub } from "react-icons/bs";
import { PiCodesandboxLogo } from "react-icons/pi";

function AboutTextColumn() {
  return (
    <>
      <Col xs={{ order: 3 }} xl={{ order: 1 }} id="aboutTextCol">
        <h2 className="columnRubrik aboutRubrik">About</h2>
        <p className="about">
          Chattria is a self-taught artist and a front-end developer. With a
          passion for creating art inspired by symbols, emotions, mythology and
          history. Without being limited to one type of art style and method,
          she is open to trying them all when required to make her vision come
          to life. As a front-end developer she guides the user through the web
          with intuitive user interfaces and great design.
        </p>
        <p className="about aboutlast">
          She is also one of three founders of HOTICE, a multi-creative studio
          founded in 2019. A space to create art without any rules and a vision
          to learn and grow. What we create is a testament to our passion for
          innovation and technology, our respect for tradition, and our
          unwavering dedication to creating art that explores different subjects
          and mediums.
        </p>

        <Container className="socialMediaCol">
          <Row id="aboutSocialTop">
            <Col className="aboutSocialMediaColumn socialCol">
              <a
                href="https://www.instagram.com/chattria/"
                target="_blank"
                rel="noreferrer"
              >
                <BsInstagram id="aboutInstagram" alt="instagram" />
              </a>
            </Col>
            <Col className="aboutSocialMediaColumn">
              <a
                href="https://www.linkedin.com/in/chattria-thinroj-palm-0b17a7136/"
                target="_blank"
                rel="noreferrer"
              >
                <BiLogoLinkedin id="aboutLinkedin" alt="linkedin" />
              </a>
            </Col>
          </Row>
          <Row id="aboutSocialBottom">
            <Col className="aboutSocialMediaColumn socialCol">
              <a
                href="https://codesandbox.io/u/chattria"
                target="_blank"
                rel="noreferrer"
              >
                <PiCodesandboxLogo id="aboutCodesandbox" alt="codesandbox" />
              </a>
            </Col>
            <Col className="aboutSocialMediaColumn ">
              <a
                href="https://github.com/chattria"
                target="_blank"
                rel="noreferrer"
              >
                <BsGithub id="aboutGithub" alt="github" />
              </a>
            </Col>
          </Row>
        </Container>
      </Col>
    </>
  );
}

export default AboutTextColumn;
